var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "wrapper__card" }, [
      _c("div", { staticClass: "wrapper__card__header" }, [
        _c("div", { staticClass: "left" }),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            [
              _c("z-btn", {
                attrs: {
                  small: "",
                  rounded: "",
                  text: "voltar",
                  icon: "$keyboard_return_rounded",
                },
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("z-btn", {
                attrs: {
                  rounded: "",
                  primary: "",
                  text: "Criar filtro",
                  icon: "mdi-plus",
                  small: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.goToPage("filterIndexPage")
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "wrapper__card__body" }, [
        _c(
          "div",
          { staticClass: "wrapper__card__body__table" },
          [
            _c("z-table", {
              attrs: {
                headers: _vm.headers,
                items: _vm.items,
                "current-page": _vm.currentPage,
                "first-item-index":
                  (_vm.currentPage - 1) * _vm.itemsPerPage + 1,
                "number-of-pages": _vm.numberOfPages,
                "last-item-index":
                  _vm.currentPage * _vm.itemsPerPage > _vm.numberOfRepos
                    ? _vm.numberOfRepos
                    : _vm.currentPage * _vm.itemsPerPage,
              },
              on: {
                next: _vm.onNext,
                previous: _vm.onPrevious,
                "change:page": _vm.onChangePage,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.actions`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "actions" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.doSome(item)
                                  },
                                },
                              },
                              [_vm._v("$restore_from_trash_rounded")]
                            ),
                            _c(
                              "v-icon",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.doSome(item)
                                  },
                                },
                              },
                              [_vm._v("$edit_outline")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }